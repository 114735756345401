import React from 'react'

function Login({ onchange, value, onsubmit, disable, loading }) {

    return (
        <div className='login-wrapper'>
            <div className="login-box">
                <h2>THANK YOU FOR PARTICIPATING IN <span>ELEVATION CONNECT!</span> </h2>
                <h4>We would be grateful if you could take out a few minutes to fill out this feedback form</h4>
                <form onSubmit={onsubmit} className='login-form'>
                    <div className='input-block'>
                        <label htmlFor="">Name</label>
                        <input
                            type="text"
                            placeholder='Please enter your name'
                            required
                            onChange={onchange("Name")}
                            value={value.Name || ''}
                            name='Name' />
                    </div>
                    <div className='input-block'>
                        <label htmlFor="email">Email ID</label>
                        <input
                            type="email"
                            placeholder='Please enter your email ID'
                            required
                            onChange={onchange('Email')}
                            value={value.Email || ''}
                            name='Email' />
                    </div>
                    <div className='input-block'>
                        <label htmlFor="">Fund</label>
                        <input
                            type="text"
                            placeholder='Please enter your fund name here'
                            onChange={onchange('Fund')}
                            value={value.Fund || ''}
                            name='Fund' />
                    </div>
                    <label htmlFor="">How would you rate the quality of interactions? 1 (lowest) - 5 (highest)</label>
                    <div className="ratingContainer input-block">
                        <input
                            type="radio"
                            name="Rating"
                            value="1"
                            id="rating1"
                            checked={value.Rating == 1}
                            onChange={onchange('Rating')} />
                        <label htmlFor="rating1" className="ratingLabel">
                            <div className={value.Rating == 1 ? 'placeholder pActive' : 'placeholder'}>1</div>
                        </label>
                        <input
                            type="radio"
                            name="Rating"
                            value="2" id="rating2"
                            checked={value.Rating == 2}
                            onChange={onchange('Rating')} />
                        <label htmlFor="rating2" className="ratingLabel">
                            <div className={value.Rating == 2 ? 'placeholder pActive' : 'placeholder'}>2</div>
                        </label>
                        <input
                            type="radio"
                            name="Rating"
                            value="3"
                            id="rating3"
                            checked={value.Rating == 3}
                            onChange={onchange('Rating')} />
                        <label htmlFor="rating3" className="ratingLabel">
                            <div className={value.Rating == 3 ? 'placeholder pActive' : 'placeholder'}>3</div>
                        </label>
                        <input
                            type="radio"
                            name="Rating"
                            value="4" id="rating4"
                            checked={value.Rating == 4}
                            onChange={onchange('Rating')} />
                        <label htmlFor="rating4" className="ratingLabel">
                            <div className={value.Rating == 4 ? 'placeholder pActive' : 'placeholder'}>4</div>
                        </label>
                        <input
                            type="radio"
                            name="Rating"
                            value="5"
                            id="rating5"
                            checked={value.Rating == 5}
                            onChange={onchange('Rating')} />
                        <label htmlFor="rating5" className="ratingLabel">
                            <div className={value.Rating == 5 ? 'placeholder pActive' : 'placeholder'}>5</div>
                        </label>
                    </div>
                    <div className='input-block'>
                        <label htmlFor="">Please let us know the portfolio companies that excited you and if we can further help facilitate second level conversations with them</label>
                        <textarea
                            type="text"
                            placeholder='Please enter your answer here'
                            onChange={onchange('PortfolioCompanies')}
                            value={value.PortfolioCompanies || ''}
                            name='PortfolioCompanies' />
                    </div>
                    <div className='input-block'>
                        <label htmlFor="">How could we improve the event? Please provide your recommendations</label>
                        <textarea
                            type="text"
                            placeholder='Please enter your answer here'
                            onChange={onchange('Message')}
                            value={value.Message || ''}
                            name='Message' />
                    </div>
                    <input disabled={disable || loading} className={`submit-btn ${disable ? 'disable' : 'active'} `} type='submit' value='Submit' />
                </form>
            </div>
        </div>
    )
}

export default Login;
