import React, { useState } from "react";
import axios from "axios";

import Header from "./component/Header";
import Login from "./component/Login";
import Success from "./component/Success";
import { BASE_ID_USER, API_KEY, FEEDBACK_TABLE, BASE_API } from './constant/index';

import './assets/style/style.css';

function App() {
  const [form, setForm] = useState({
    Name: '',
    Email: '',
    Fund: '',
    Rating: '',
    PortfolioCompanies:'',
    Message: ''
  })
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleFormChange = (field) => (e) => {
    const { value } = e.target;
    setForm((currentState) => ({
      ...currentState,
      [field]: value
    }));
  }

  const getErrors = (form) => {
    const errors = {};

    if (!form.Name) {
      errors.Name = "Enter Name";
    }

    if (!form.Email) {
      errors.Email = "Enter email"
    }


    return errors
  }

  const handleSubmitFrom = async (e) => {
    console.log('click is happen');
    e && e.preventDefault();
    setLoading(true)
    const errors = getErrors(form);

    if (Object.keys(errors).length > 0) {
      !showError && setShowError(true);
      setErrorMessage(errors)
      return;
    }

    axios.post(`${BASE_API}/${BASE_ID_USER}/${FEEDBACK_TABLE}/`,
      {
        "fields": {
          "Name": form.Name,
          "Email": form.Email,
          "Fund": form.Fund,
          "Rating": form.Rating,
          "PortfolioCompanies": form.PortfolioCompanies,
          "Message": form.Message
        },
      }, { headers: { "Authorization": `Bearer ${API_KEY}` } }).then((res) => setSuccess(true)).catch(err => console.log(err))
  }

  const disable = form.Name === ''  || form.Email === ''  || form.Rating === ''

  return (
    <>
      <Header />
      {success ? <Success /> : <Login errorMessage={errorMessage} disable={disable} onchange={handleFormChange} value={form} onsubmit={handleSubmitFrom} loading={loading} /> }
    </>
  );
}

export default App;
