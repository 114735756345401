import React from 'react';

import Check from '../assets/media/success.png'

function Success({ event }) {

  return (
    <div className='success-wrapper'>
      <div className='success-content-wrapper'>
        <img className='success-img' src={Check} alt='success'></img>
        <div>
          <h3>Thank you for your feedback!</h3>
        </div>
      </div>
    </div>
  )
}

export default Success;
