import React from 'react'

function Header() {
  return (
    <header className='header'>
      <svg xmlns="http://www.w3.org/2000/svg" width="218" height="69" viewBox="0 0 218 69" fill="none">
        <g clipPath="url(#clip0_2001_106)">
          <path d="M53.36 56.9471C53.36 50.6872 58.48 45.5836 64.76 45.5836C69.23 45.5836 73.16 48.235 75.04 52.5611L70.13 53.9268C69.04 51.7438 67.1 50.4978 64.76 50.4679C61.2 50.4679 58.48 53.2689 58.48 56.9471C58.48 60.6252 61.2 63.3963 64.76 63.3963C67.13 63.3963 68.98 62.2401 70.04 60.0969L75.01 61.4027C73.17 65.669 69.23 68.2807 64.76 68.2807C58.48 68.2807 53.36 63.207 53.36 56.9471ZM74.39 61.7118L70.3 60.5953C69.11 62.7086 67.21 63.8349 64.77 63.8349C60.96 63.8349 58.05 60.8744 58.05 56.957C58.05 53.0396 60.96 50.0392 64.77 50.0392C67.18 50.0692 69.18 51.2852 70.36 53.4383L74.42 52.282C72.55 48.3846 68.92 46.0221 64.77 46.0221C58.74 46.0221 53.8 50.9463 53.8 56.957C53.8 62.9677 58.74 67.862 64.77 67.862C68.93 67.862 72.55 65.5295 74.39 61.7317V61.7118Z" fill="#ffffff" />
          <path d="M76.08 56.9471C76.08 50.6872 81.2 45.5836 87.48 45.5836C93.76 45.5836 98.85 50.6872 98.88 56.9471C98.91 63.1771 93.79 68.2807 87.48 68.2807C81.17 68.2807 76.08 63.207 76.08 56.9471ZM98.45 56.9471C98.42 50.9065 93.51 46.0122 87.48 46.0122C81.45 46.0122 76.51 50.9364 76.51 56.9471C76.51 62.9578 81.45 67.852 87.48 67.852C93.51 67.852 98.48 62.9279 98.45 56.9471ZM80.76 56.9471C80.76 53.0197 83.7 49.9396 87.48 49.9396C91.26 49.9396 94.2 52.9898 94.2 56.9471C94.2 60.9044 91.26 63.9246 87.48 63.9246C83.7 63.9246 80.76 60.8744 80.76 56.9471ZM93.76 56.9471C93.76 53.239 91.01 50.3782 87.48 50.3782C83.95 50.3782 81.2 53.2788 81.2 56.9471C81.2 60.6153 83.92 63.4861 87.48 63.4861C91.04 63.4861 93.76 60.6552 93.76 56.9471Z" fill="#ffffff" />
          <path d="M101.04 45.7929H105.98L115.57 59.9275V45.7929H120.51L130.13 59.9674V45.7929H135.1V68.0315H130.19L120.57 53.857V68.0315H115.66L106.01 53.8271V68.0315H101.04V45.7929ZM105.57 67.5929V52.3917L115.91 67.5929H120.13V52.4216L130.44 67.5929H134.66V46.2315H130.57V61.4028L120.26 46.2315H116.01V61.3629L105.73 46.2315H101.48V67.5929H105.57Z" fill="#ffffff" />
          <path d="M138.19 63.516V45.7929H154.47V50.5576H143.32V54.5747H153.22V59.2497H143.32V63.2668H154.47V68.0315H140.85L138.19 63.516ZM154.03 67.5929V63.6954H142.88V58.8011H152.78V55.0033H142.88V50.109H154.03V46.2116H138.63V63.3764L141.1 67.5829H154.03V67.5929Z" fill="#ffffff" />
          <path d="M155.75 56.9471C155.75 50.6872 160.87 45.5836 167.15 45.5836C171.62 45.5836 175.55 48.235 177.43 52.5611L172.52 53.9268C171.43 51.7438 169.49 50.4978 167.15 50.4679C163.59 50.4679 160.87 53.2689 160.87 56.9471C160.87 60.6252 163.59 63.3963 167.15 63.3963C169.52 63.3963 171.37 62.2401 172.43 60.0969L177.4 61.4027C175.56 65.669 171.62 68.2807 167.15 68.2807C160.87 68.2807 155.75 63.207 155.75 56.9471ZM176.77 61.7118L172.68 60.5953C171.49 62.7086 169.59 63.8349 167.15 63.8349C163.34 63.8349 160.43 60.8744 160.43 56.957C160.43 53.0396 163.34 50.0392 167.15 50.0392C169.56 50.0692 171.56 51.2852 172.74 53.4383L176.8 52.282C174.93 48.3846 171.3 46.0221 167.15 46.0221C161.12 46.0221 156.18 50.9463 156.18 56.957C156.18 62.9677 161.12 67.862 167.15 67.862C171.31 67.862 174.93 65.5295 176.77 61.7317V61.7118Z" fill="#ffffff" />
          <path d="M184.64 50.5875H177.99V45.7929H196.17V50.5875H189.77V68.0315H184.65V50.5875H184.64ZM189.33 67.5929V50.1489H195.73V46.2215H178.42V50.1489H185.07V67.5929H189.32H189.33Z" fill="#ffffff" />
          <path d="M53.36 56.9471C53.36 50.6872 58.48 45.5836 64.76 45.5836C69.23 45.5836 73.16 48.235 75.04 52.5611L70.13 53.9268C69.04 51.7438 67.1 50.4978 64.76 50.4679C61.2 50.4679 58.48 53.2689 58.48 56.9471C58.48 60.6252 61.2 63.3963 64.76 63.3963C67.13 63.3963 68.98 62.2401 70.04 60.0969L75.01 61.4027C73.17 65.669 69.23 68.2807 64.76 68.2807C58.48 68.2807 53.36 63.207 53.36 56.9471ZM74.39 61.7118L70.3 60.5953C69.11 62.7086 67.21 63.8349 64.77 63.8349C60.96 63.8349 58.05 60.8744 58.05 56.957C58.05 53.0396 60.96 50.0392 64.77 50.0392C67.18 50.0692 69.18 51.2852 70.36 53.4383L74.42 52.282C72.55 48.3846 68.92 46.0221 64.77 46.0221C58.74 46.0221 53.8 50.9463 53.8 56.957C53.8 62.9677 58.74 67.862 64.77 67.862C68.93 67.862 72.55 65.5295 74.39 61.7317V61.7118Z" stroke="white" strokeWidth="0.43" strokeMiterlimit="10" />
          <path d="M76.08 56.9471C76.08 50.6872 81.2 45.5836 87.48 45.5836C93.76 45.5836 98.85 50.6872 98.88 56.9471C98.91 63.1771 93.79 68.2807 87.48 68.2807C81.17 68.2807 76.08 63.207 76.08 56.9471ZM98.45 56.9471C98.42 50.9065 93.51 46.0122 87.48 46.0122C81.45 46.0122 76.51 50.9364 76.51 56.9471C76.51 62.9578 81.45 67.852 87.48 67.852C93.51 67.852 98.48 62.9279 98.45 56.9471ZM80.76 56.9471C80.76 53.0197 83.7 49.9396 87.48 49.9396C91.26 49.9396 94.2 52.9898 94.2 56.9471C94.2 60.9044 91.26 63.9246 87.48 63.9246C83.7 63.9246 80.76 60.8744 80.76 56.9471ZM93.76 56.9471C93.76 53.239 91.01 50.3782 87.48 50.3782C83.95 50.3782 81.2 53.2788 81.2 56.9471C81.2 60.6153 83.92 63.4861 87.48 63.4861C91.04 63.4861 93.76 60.6552 93.76 56.9471Z" stroke="white" strokeWidth="0.43" strokeMiterlimit="10" />
          <path d="M101.04 45.7929H105.98L115.57 59.9275V45.7929H120.51L130.13 59.9674V45.7929H135.1V68.0315H130.19L120.57 53.857V68.0315H115.66L106.01 53.8271V68.0315H101.04V45.7929ZM105.57 67.5929V52.3917L115.91 67.5929H120.13V52.4216L130.44 67.5929H134.66V46.2315H130.57V61.4028L120.26 46.2315H116.01V61.3629L105.73 46.2315H101.48V67.5929H105.57Z" stroke="white" strokeWidth="0.43" strokeMiterlimit="10" />
          <path d="M138.19 63.516V45.7929H154.47V50.5576H143.32V54.5747H153.22V59.2497H143.32V63.2668H154.47V68.0315H140.85L138.19 63.516ZM154.03 67.5929V63.6954H142.88V58.8011H152.78V55.0033H142.88V50.109H154.03V46.2116H138.63V63.3764L141.1 67.5829H154.03V67.5929Z" stroke="white" strokeWidth="0.43" strokeMiterlimit="10" />
          <path d="M155.75 56.9471C155.75 50.6872 160.87 45.5836 167.15 45.5836C171.62 45.5836 175.55 48.235 177.43 52.5611L172.52 53.9268C171.43 51.7438 169.49 50.4978 167.15 50.4679C163.59 50.4679 160.87 53.2689 160.87 56.9471C160.87 60.6252 163.59 63.3963 167.15 63.3963C169.52 63.3963 171.37 62.2401 172.43 60.0969L177.4 61.4027C175.56 65.669 171.62 68.2807 167.15 68.2807C160.87 68.2807 155.75 63.207 155.75 56.9471ZM176.77 61.7118L172.68 60.5953C171.49 62.7086 169.59 63.8349 167.15 63.8349C163.34 63.8349 160.43 60.8744 160.43 56.957C160.43 53.0396 163.34 50.0392 167.15 50.0392C169.56 50.0692 171.56 51.2852 172.74 53.4383L176.8 52.282C174.93 48.3846 171.3 46.0221 167.15 46.0221C161.12 46.0221 156.18 50.9463 156.18 56.957C156.18 62.9677 161.12 67.862 167.15 67.862C171.31 67.862 174.93 65.5295 176.77 61.7317V61.7118Z" stroke="white" strokeWidth="0.43" strokeMiterlimit="10" />
          <path d="M184.64 50.5875H177.99V45.7929H196.17V50.5875H189.77V68.0315H184.65V50.5875H184.64ZM189.33 67.5929V50.1489H195.73V46.2215H178.42V50.1489H185.07V67.5929H189.32H189.33Z" stroke="white" strokeWidth="0.43" strokeMiterlimit="10" />
          <path d="M53.36 56.9471C53.36 50.6872 58.48 45.5836 64.76 45.5836C69.23 45.5836 73.16 48.235 75.04 52.5611L70.13 53.9268C69.04 51.7438 67.1 50.4978 64.76 50.4679C61.2 50.4679 58.48 53.2689 58.48 56.9471C58.48 60.6252 61.2 63.3963 64.76 63.3963C67.13 63.3963 68.98 62.2401 70.04 60.0969L75.01 61.4027C73.17 65.669 69.23 68.2807 64.76 68.2807C58.48 68.2807 53.36 63.207 53.36 56.9471ZM74.39 61.7118L70.3 60.5953C69.11 62.7086 67.21 63.8349 64.77 63.8349C60.96 63.8349 58.05 60.8744 58.05 56.957C58.05 53.0396 60.96 50.0392 64.77 50.0392C67.18 50.0692 69.18 51.2852 70.36 53.4383L74.42 52.282C72.55 48.3846 68.92 46.0221 64.77 46.0221C58.74 46.0221 53.8 50.9463 53.8 56.957C53.8 62.9677 58.74 67.862 64.77 67.862C68.93 67.862 72.55 65.5295 74.39 61.7317V61.7118Z" stroke="#ffffff" strokeWidth="0.5" strokeMiterlimit="10" />
          <path d="M76.08 56.9471C76.08 50.6872 81.2 45.5836 87.48 45.5836C93.76 45.5836 98.85 50.6872 98.88 56.9471C98.91 63.1771 93.79 68.2807 87.48 68.2807C81.17 68.2807 76.08 63.207 76.08 56.9471ZM98.45 56.9471C98.42 50.9065 93.51 46.0122 87.48 46.0122C81.45 46.0122 76.51 50.9364 76.51 56.9471C76.51 62.9578 81.45 67.852 87.48 67.852C93.51 67.852 98.48 62.9279 98.45 56.9471ZM80.76 56.9471C80.76 53.0197 83.7 49.9396 87.48 49.9396C91.26 49.9396 94.2 52.9898 94.2 56.9471C94.2 60.9044 91.26 63.9246 87.48 63.9246C83.7 63.9246 80.76 60.8744 80.76 56.9471ZM93.76 56.9471C93.76 53.239 91.01 50.3782 87.48 50.3782C83.95 50.3782 81.2 53.2788 81.2 56.9471C81.2 60.6153 83.92 63.4861 87.48 63.4861C91.04 63.4861 93.76 60.6552 93.76 56.9471Z" stroke="#ffffff" strokeWidth="0.5" strokeMiterlimit="10" />
          <path d="M101.04 45.7929H105.98L115.57 59.9275V45.7929H120.51L130.13 59.9674V45.7929H135.1V68.0315H130.19L120.57 53.857V68.0315H115.66L106.01 53.8271V68.0315H101.04V45.7929ZM105.57 67.5929V52.3917L115.91 67.5929H120.13V52.4216L130.44 67.5929H134.66V46.2315H130.57V61.4028L120.26 46.2315H116.01V61.3629L105.73 46.2315H101.48V67.5929H105.57Z" stroke="#ffffff" strokeWidth="0.5" strokeMiterlimit="10" />
          <path d="M138.19 63.516V45.7929H154.47V50.5576H143.32V54.5747H153.22V59.2497H143.32V63.2668H154.47V68.0315H140.85L138.19 63.516ZM154.03 67.5929V63.6954H142.88V58.8011H152.78V55.0033H142.88V50.109H154.03V46.2116H138.63V63.3764L141.1 67.5829H154.03V67.5929Z" stroke="#ffffff" strokeWidth="0.5" strokeMiterlimit="10" />
          <path d="M155.75 56.9471C155.75 50.6872 160.87 45.5836 167.15 45.5836C171.62 45.5836 175.55 48.235 177.43 52.5611L172.52 53.9268C171.43 51.7438 169.49 50.4978 167.15 50.4679C163.59 50.4679 160.87 53.2689 160.87 56.9471C160.87 60.6252 163.59 63.3963 167.15 63.3963C169.52 63.3963 171.37 62.2401 172.43 60.0969L177.4 61.4027C175.56 65.669 171.62 68.2807 167.15 68.2807C160.87 68.2807 155.75 63.207 155.75 56.9471ZM176.77 61.7118L172.68 60.5953C171.49 62.7086 169.59 63.8349 167.15 63.8349C163.34 63.8349 160.43 60.8744 160.43 56.957C160.43 53.0396 163.34 50.0392 167.15 50.0392C169.56 50.0692 171.56 51.2852 172.74 53.4383L176.8 52.282C174.93 48.3846 171.3 46.0221 167.15 46.0221C161.12 46.0221 156.18 50.9463 156.18 56.957C156.18 62.9677 161.12 67.862 167.15 67.862C171.31 67.862 174.93 65.5295 176.77 61.7317V61.7118Z" stroke="#ffffff" strokeWidth="0.5" strokeMiterlimit="10" />
          <path d="M184.64 50.5875H177.99V45.7929H196.17V50.5875H189.77V68.0315H184.65V50.5875H184.64ZM189.33 67.5929V50.1489H195.73V46.2215H178.42V50.1489H185.07V67.5929H189.32H189.33Z" stroke="#ffffff" strokeWidth="0.5" strokeMiterlimit="10" />
          <path d="M68.6 19.5073H53.95C53.77 19.5073 53.62 19.6568 53.62 19.8363V37.7089C53.62 37.8883 53.77 38.0378 53.95 38.0378H68.6C68.78 38.0378 68.93 37.8883 68.93 37.7089V36.3333C68.93 36.1539 68.78 36.0043 68.6 36.0043H55.81V29.0965H67.91C68.09 29.0965 68.24 28.947 68.24 28.7676V27.4618C68.24 27.2824 68.09 27.1328 67.91 27.1328H55.81V21.5408H68.6C68.78 21.5408 68.93 21.3913 68.93 21.2119V19.8363C68.93 19.6568 68.78 19.5073 68.6 19.5073Z" fill="#ffffff" />
          <path d="M88 35.9744H75.83V19.8363C75.83 19.6568 75.68 19.5073 75.5 19.5073H73.96C73.78 19.5073 73.63 19.6568 73.63 19.8363V37.7089C73.63 37.8883 73.78 38.0378 73.96 38.0378H88C88.18 38.0378 88.33 37.8883 88.33 37.7089V36.3034C88.33 36.124 88.18 35.9744 88 35.9744Z" fill="#ffffff" />
          <path d="M107.51 19.5073H92.86C92.68 19.5073 92.53 19.6568 92.53 19.8363V37.7089C92.53 37.8883 92.68 38.0378 92.86 38.0378H107.51C107.69 38.0378 107.84 37.8883 107.84 37.7089V36.3333C107.84 36.1539 107.69 36.0043 107.51 36.0043H94.72V29.0965H106.82C107 29.0965 107.15 28.947 107.15 28.7676V27.4618C107.15 27.2824 107 27.1328 106.82 27.1328H94.72V21.5408H107.2C107.55 21.5408 107.84 21.2517 107.84 20.9028V19.8363C107.84 19.6568 107.69 19.5073 107.51 19.5073Z" fill="#ffffff" />
          <path d="M129.46 19.5073H127.85C127.72 19.5073 127.6 19.5871 127.55 19.6967L120.47 35.2767L113.42 19.6967C113.37 19.5771 113.25 19.5073 113.12 19.5073H111.51C111.4 19.5073 111.29 19.5671 111.23 19.6568C111.17 19.7466 111.16 19.8662 111.21 19.9659L119.33 37.8384C119.38 37.9581 119.5 38.0278 119.63 38.0278H121.32C121.45 38.0278 121.57 37.9481 121.62 37.8384L129.77 19.9659C129.82 19.8662 129.81 19.7466 129.75 19.6568C129.69 19.5671 129.59 19.5073 129.47 19.5073H129.46Z" fill="#ffffff" />
          <path d="M140.66 19.6967C140.61 19.5771 140.49 19.5073 140.36 19.5073H138.67C138.54 19.5073 138.42 19.5871 138.37 19.6967L130.25 37.5693C130.2 37.669 130.21 37.7886 130.27 37.8783C130.33 37.968 130.43 38.0278 130.55 38.0278H132.16C132.29 38.0278 132.41 37.9581 132.46 37.8384L133.68 35.167H145.37L146.59 37.8384C146.64 37.9581 146.76 38.0278 146.89 38.0278H148.5C148.61 38.0278 148.72 37.968 148.78 37.8783C148.84 37.7886 148.85 37.669 148.8 37.5693L140.65 19.6967H140.66ZM144.49 33.2133H134.57L139.52 22.2585L144.49 33.2133Z" fill="#ffffff" />
          <path d="M165.61 19.5073H148.1C147.99 19.5073 147.89 19.5671 147.83 19.6568C147.77 19.7466 147.76 19.8662 147.8 19.9659L148.42 21.3713C148.47 21.491 148.59 21.5707 148.72 21.5707H155.77V37.7089C155.77 37.8883 155.92 38.0378 156.1 38.0378H157.64C157.82 38.0378 157.97 37.8883 157.97 37.7089V21.5707H164.99C165.12 21.5707 165.24 21.491 165.29 21.3713L165.91 19.9659C165.96 19.8662 165.95 19.7466 165.89 19.6568C165.83 19.5671 165.73 19.5073 165.62 19.5073H165.61Z" fill="#ffffff" />
          <path d="M171.65 19.5073H170.12C169.938 19.5073 169.79 19.6546 169.79 19.8363V37.7089C169.79 37.8905 169.938 38.0378 170.12 38.0378H171.65C171.832 38.0378 171.98 37.8905 171.98 37.7089V19.8363C171.98 19.6546 171.832 19.5073 171.65 19.5073Z" fill="#ffffff" />
          <path d="M186.55 19.1186C183.58 19.1186 181.19 20.0556 179.45 21.8997C177.71 23.7138 176.82 26.0264 176.82 28.7676C176.82 31.5088 177.7 33.8114 179.45 35.6555C181.21 37.4796 183.59 38.4166 186.55 38.4166C189.51 38.4166 191.92 37.4896 193.68 35.6555C195.42 33.8114 196.31 31.4988 196.31 28.7676C196.31 26.0364 195.43 23.7238 193.68 21.9096C191.93 20.0655 189.53 19.1286 186.55 19.1286V19.1186ZM194.11 28.7676C194.11 30.9606 193.46 32.7349 192.12 34.1902C190.82 35.6056 188.94 36.3333 186.55 36.3333C184.16 36.3333 182.28 35.6156 180.98 34.1902C179.66 32.7249 179.01 30.9506 179.01 28.7676C179.01 26.5846 179.65 24.8103 180.97 23.345C182.27 21.9196 184.15 21.2019 186.54 21.2019C188.93 21.2019 190.81 21.9196 192.11 23.345C193.45 24.8003 194.1 26.5746 194.1 28.7676H194.11Z" fill="#ffffff" />
          <path d="M217.42 19.5073H215.88C215.7 19.5073 215.55 19.6568 215.55 19.8363L215.57 35.0773L203.69 19.6369C203.63 19.5572 203.53 19.5073 203.43 19.5073H201.48C201.3 19.5073 201.15 19.6568 201.15 19.8363V37.7089C201.15 37.8883 201.3 38.0378 201.48 38.0378H203.02C203.2 38.0378 203.35 37.8883 203.35 37.7089L203.33 22.4678L215.21 37.9082C215.27 37.988 215.37 38.0378 215.47 38.0378H217.42C217.6 38.0378 217.75 37.8883 217.75 37.7089V19.8363C217.75 19.6568 217.6 19.5073 217.42 19.5073Z" fill="#ffffff" />
          <path d="M19.64 48.3846V0.518333C19.64 0.0398699 20.24 -0.179426 20.55 0.18939L44.2 28.2891C44.48 28.6281 44.24 29.1364 43.81 29.1364H29.88C29.68 29.1364 29.49 29.256 29.41 29.4355L20.63 48.6039C20.4 49.1023 19.64 48.9428 19.64 48.3946V48.3846Z" fill="url(#paint0_linear_2001_106)" />
          <path d="M16.66 18.9491C16.66 18.4707 16.06 18.2514 15.75 18.6202L0.120001 37.1806C-0.159999 37.5195 0.0800006 38.0278 0.510001 38.0278H10.49C10.69 38.0278 10.87 38.1475 10.96 38.3269L15.67 48.5939C15.9 49.0923 16.65 48.9328 16.65 48.3846V18.9591L16.66 18.9491Z" fill="url(#paint1_linear_2001_106)" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_2001_106" x1="31.98" y1="46.8296" x2="31.98" y2="1.9936" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ffffff" />
            <stop offset="0.5" stopColor="#ffffff" />
          </linearGradient>
          <linearGradient id="paint1_linear_2001_106" x1="8.33" y1="47.6071" x2="8.33" y2="19.6768" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ffffff" />
            <stop offset="0.5" stopColor="#ffffff" />
          </linearGradient>
          <clipPath id="clip0_2001_106">
            <rect width="217.75" height="68.5" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </header>
  )
}

export default Header;
